import React, { useEffect, useState, useRef } from 'react'
import styled from 'styled-components'
import { Amplify } from 'aws-amplify'
import { withAuthenticator } from '@aws-amplify/ui-react'
import awsExports from '../../aws-exports'
import '@aws-amplify/ui-react/styles.css'
import * as Api from '../../util/Api'
import * as Constants from '../../util/constants'
import { Loader, Message as Alert, useToaster } from 'rsuite'
import Btn from '../../components/Btn'
import * as SaguaroBtn from '../../components/Btn'
import MessageBubble from '../../components/Message.bubble.component'
import MessageInput from '../../components/Message.input.component'
import { sortByDate, sortByDateDesc } from '../../util/util'
import ChatTitle from '../../components/Chat.title.component'
import dayjs from 'dayjs'
import UserSelector from '../../components/Users.selector.component'

Amplify.configure(awsExports)

function Home() {
	const [loading, setLoading] = useState({ users: true, chats: true, sendingMessage: false })
	const [users, setUsers] = useState()
	const [currUser, setCurrUser] = useState()
	const [selectedUser, setSelectedUser] = useState()
	const [chat, setChat] = useState()
	const [message, setMessage] = useState()
	const convoRef = useRef()
	const toaster = useToaster()

	useEffect(() => {
		loadUsers()
	}, [])

	const loadUsers = async () => {
		setLoading({ ...loading, users: true })
		try {
			const [me, users] = await Promise.all([
				Api.getMe(),
				Api.getUsers()
			])
			setCurrUser(me)
			setSelectedUser(me)
			setUsers(users)
		} catch (e) {
			console.error('Error loading users: ', e)
			toaster.push(
				<Alert showIcon closable type='error'>Error loading users</Alert>,
				{ duration: 3_000 }
			)
		}
		setLoading({ ...loading, users: false })
	}

	useEffect(() => {
		console.log('selected chat', chat)
		if (chat) {
			scrollToBottom()
		}
	}, [chat])

	const scrollToBottom = () => {
		if (convoRef.current) {
			convoRef.current.scrollTop = convoRef.current.scrollHeight
		}
	}

	const selectNewChat = () => {
		setChat({ title: `New Chat ${dayjs().format('h:mma')}`, user: currUser })
	}

	const sendMessage = async () => {
		setLoading({ ...loading, sendMessage: true })
		const isNewChat = !chat.id
		console.log('--- SELECTeD USERS: ', selectedUser)
		console.log('--- CURR USER USERS: ', currUser)
		const source = selectedUser.id !== currUser.id ? 'CONSULTANT' : 'USER'
		let usr

		if (isNewChat) {
			let newChat = await Api.createChat(chat)
			let updatedChat = await Api.sendMessage(newChat.id, message, source)
			usr = {
				...selectedUser,
				chats: [updatedChat, ...selectedUser.chats]
			}
			setChat(updatedChat)
		} else {
			const updatedChat = await Api.sendMessage(chat.id, message, source)
			usr = {
				...selectedUser,
				chats: selectedUser.chats.map(existingChat => existingChat.id === updatedChat.id ? updatedChat : existingChat) 
			}
			setChat(updatedChat)
		}

		setSelectedUser({ ...usr})
		setUsers(users.map(u => u.id === usr.id ? usr : u))
		setMessage('')
		setLoading({ ...loading, sendMessage: false })
	}

	const updateChatTitle = async (title) => {
		if (chat.id) {
			const c = await Api.updateChat({ ...chat, title })
			setChat(c)
		} else {
			setChat({ ...chat, title })
		}
	}

	useEffect(() => {
		console.log("--- SELECTED USER = ", selectedUser)
	}, [selectedUser])

	if (loading.users) {
		return <Loader center backdrop content='Loading Page' />
	}
	return (
		<Ctr>
			<Sidebar>
				<Header $justify='space-between'>
					<SaguaroBtn.ProfileBtn user={currUser} />
					<Btn onClick={selectNewChat} active={chat && !chat.id}>+ New Chat</Btn>
				</Header>
				<ChatNav>
					{currUser.isAdmin && (
						<UserSelector 
							users={users}
							value={selectedUser?.id}
							onSelect={user => setSelectedUser(user)}
							style={{ width: '100%' }}
						/>
					)}
					{selectedUser?.chats?.sort(sortByDateDesc).map(c => (
						<SaguaroBtn.ChatBtn 
							key={`chat_${c.id}`} 
							chat={c} 
							onClick={() => setChat(c)}
							active={c.id === chat?.id} 
						/>
					))}
				</ChatNav>
			</Sidebar>
			<ConvoCtr>
				<Header $justify='center'>
					{chat 
						? <ChatTitle chat={chat} save={updateChatTitle} /> 
						: selectedUser?.chats?.length ? 'Please create a new chat or select an existing chat to begin' : 'Please create a new chat to begin'
					}
				</Header>
				<Convo ref={convoRef}>
					{chat?.messages?.sort(sortByDate)?.map(m => (
						<MessageBubble key={`message_${m.id}`} message={m} />
					))}
					{chat && (
						<MessageInputCtr>
							<MessageInput 
								message={message} 
								setMessage={setMessage} 
								send={sendMessage} 
								sending={loading.sendingMessage}
							/>
						</MessageInputCtr>
					)}
				</Convo>
			</ConvoCtr>
		</Ctr>
	)
}

const Ctr = styled.div`
	display: grid;
	grid-template-columns: ${Constants.SIDEBAR_WIDTH}px 1fr;
`
const Sidebar = styled.div`
	background-color: ${Constants.COLORS.GREY_LIGHT};
	height: 100vh;
	padding: 0 20px;
`
const ChatNav = styled.nav`
	display: flex;
	flex-direction: column;
	align-items: center;
	height: calc(100vh - ${Constants.HEADER_HEIGHT}px);
	overflow: scroll;
	button {
		margin: 10px 0;
		width: 100%;
	}
`
const ConvoCtr = styled.div`
`
const Header = styled.div`
	height: ${Constants.HEADER_HEIGHT}px;
	display: flex;
	align-items: center;
	justify-content: ${p => p.$justify ?? 'center'};
	background-color: ${Constants.COLORS.GREY_LIGHT};
`
const Convo = styled.main`
	display: flex;
	flex-direction: column;
	height: calc(100vh - ${Constants.HEADER_HEIGHT}px);
	padding-bottom: 80px;
	overflow: scroll;
	padding-top: 60px;
`
const MessageInputCtr = styled.div`
	position: fixed;
	bottom: 40px;
	width: calc(100vw - ${Constants.SIDEBAR_WIDTH}px);
`

export default withAuthenticator(Home)
