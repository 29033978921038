import React, { useEffect, useRef, useState } from 'react'

export default function ChatTitle({ chat, save }) {
	const [edit, setEdit] = useState(false)
	const [newTitle, setNewTitle] = useState()
	const inputRef = useRef()

	useEffect(() => {
		if (chat)
			setEdit(false)
	}, [chat])

	useEffect(() => {
		if (edit && inputRef.current) {
			inputRef.current.focus()
		}
	}, [edit])

	const triggerEdit = () => {
		setEdit(true)
	}

	const close = () => {
		setEdit(false)
		setNewTitle()
	}

	const handleSave = async () => {
		await save(newTitle)
		setNewTitle()
	}

	const handleEnter = async (e) => {
		if (e.key === 'Enter') {
			await handleSave()
		}
	}

	return (
		<div style={{ display: 'flex' }}>
			{!edit ? (<>
				<h6 style={{ fontSize: 28, marginRight: 12 }}>{chat.title}</h6>
				<span className="material-symbols-outlined" style={{ margin: '0 10px', cursor: 'pointer' }} onClick={triggerEdit}>
					edit
				</span>
			</>) : (
				<div style={{ display: 'flex' }}>
					<input 
						placeholder='New Title' 
						value={newTitle} 
						onChange={e => setNewTitle(e.target.value)} 
						ref={inputRef}
						onKeyDown={handleEnter}
					/>
					<span className="material-symbols-outlined" style={{ margin: '0 10px', cursor: 'pointer' }} onClick={handleSave}>
						done
					</span>
					<span className="material-symbols-outlined" onClick={close} style={{ cursor: 'pointer' }}>close</span>
				</div>
			)}
		</div>
	)
}
