import { getBearerToken } from "./util"

const url = window.location.href.includes('https://')
	? 'https://saguaro-api.jacobscreative.io/api'
	: 'http://localhost:8080/api'

const makeRequest = async (endpoint, method = 'GET', body) => {
	const token = await getBearerToken()
	const args = !body ? {} : { body: JSON.stringify(body) }
	const res = await fetch(`${url}/${endpoint}`, {
		method,
		headers: {
			Authorization: `Bearer ${token}`,
			'Content-Type': 'application/json'
		},
		...args	
	})
	if (res.status !== 200) {
		throw new Error('Error loading user')
	}
	return res
}

export async function getMe() {
	const res = await makeRequest(`users/me`)
	const { user } = await res.json()
	return user
}

export async function getUsers() {
	const res = await makeRequest('users')
	const { users } = await res.json()
	return users
}

export async function getChats() {
	const res = await makeRequest(`chats`)
	const { chats } = await res.json()
	return chats
}

export async function getChat(chatId) {
	const res = await makeRequest(`chat/${chatId}`)
	const { chat } = await res.json() // will include messages as well
	return chat
}

export async function createChat(newChat) {
	const res = await makeRequest(`chats`, 'POST', newChat)
	const { chat } = await res.json()
	return chat
}

export async function sendMessage(chatId, content, source) {
	const res = await makeRequest(`chat/${chatId}/message`, 'POST', { content, source })
	const { chat } = await res.json()
	return chat
}

export async function updateChat(chat) {
	const res = await makeRequest(`chat/${chat.id}`, 'PATCH', { ...chat })
	const { chat: c } = await res.json()
	return c
}
