import React, { useState } from 'react'
import styled from 'styled-components'
import * as Constants from '../util/constants'
import { Avatar, Button, Drawer } from 'rsuite';
import { Auth } from 'aws-amplify';
import { sortByDate } from '../util/util';

export default function Btn({ onClick, active, children }) {
	return (
		<BtnCtr onClick={onClick} $active={active}>
			{children}
		</BtnCtr>
	)
}

export function ChatBtn({ onClick, active, chat }) {
	const lastMessage = chat.messages.sort(sortByDate)[chat.messages.length - 1]
	return (
    <Btn onClick={onClick} active={active}>
      <h6 style={{ textAlign: 'start' }}>{chat.title}</h6>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
				<div>
					<span className="material-symbols-outlined">chat_bubble</span>
				</div>
				<div>
					{lastMessage?.content?.trim()?.substr(0, 20) ?? ''}
				</div>
      </div>
    </Btn>
  );
}

export function ProfileBtn({ user }) {
	const [drawer, setDrawer] = useState(false)
	const logout = async () => {
		await Auth.signOut()
	}
	return (<>
		<Drawer open={drawer} onClose={() => setDrawer(false)} placement='left'>
			<div style={{ display: 'flex', justifyContent: 'space-around', marginTop: 20 }}>
				<div>
					<h6>TODO: Add settings form</h6>
					<div>signed in as {user.email}</div>
				</div>
				<Button style={{ marginTop: 10}} onClick={logout} appearance='primary'>
					Sign Out
				</Button>
			</div>
		</Drawer>
		<Btn onClick={() => setDrawer(true)}>
			<div style={{ display: 'flex', alignItems: 'center', padding: 10 }}>
				<Avatar circle content={`${user.email[0]}`} size='sm' />
				<div style={{ marginLeft: 10 }}>My Profile</div>
			</div>
		</Btn>
	</>)
}

const BtnCtr = styled.button`
	background-color: ${p => p.$active ? Constants.COLORS.SELECTED : Constants.COLORS.GREY_DARK};
	border: ${p => p.$active ? 'solid 1px #3079FC' : 'none'};
	border-radius: 10px;
`