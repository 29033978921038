import React from 'react'
import { SelectPicker } from 'rsuite'

export default function UserSelector({ users, value, onSelect, ...rsuiteProps }) {
	return (
		<SelectPicker 
			data={users}
			labelKey='email'
			valueKey='id'
			value={value}
			onSelect={v => {
				onSelect(users.find(u => u.id === v))
			}}
			{...rsuiteProps}
		/>
	)
}