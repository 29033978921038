import { Auth } from "aws-amplify";
import dayjs from "dayjs";

export const delay = (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export async function getBearerToken() {
	const sessionToken = await Auth.currentSession()
	return sessionToken.accessToken['jwtToken']
}

export async function getGroups() {
	const user = await Auth.currentAuthenticatedUser()
	return user.signInUserSession.accessToken.payload["cognito:groups"]
}

export async function iAmAdmin() {
	const groups = await getGroups()
	return groups?.includes('ADMIN') ?? false
}

export const sortByDate = (a, b) => 
	dayjs(a.createdAt).unix() - dayjs(b.createdAt).unix()

export const sortByDateDesc = (a, b) =>
	dayjs(b.createdAt).unix() - dayjs(a.createdAt).unix()