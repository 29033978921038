import React from 'react'
import styled from 'styled-components'

export default function MessageBubble({ message }) {
	return (
		<Bubble source={message.source}>
			{message.content}
		</Bubble>
	)
}

const Bubble = styled.pre`
	padding: 10px 33px;
	justify-self: flex-end;
	margin: 2px 70px;
	max-width: 600px;
	border-radius: 20px;
	font-size: 20px;
	${p => {
		let backgroundColor = '#3079FC'
		let alignSelf = 'flex-end'
		let color = 'white'
		if (p.source === 'CONSULTANT') {
			alignSelf = 'flex-start'
			backgroundColor = '#8AD6EE'
			color = 'black'
		}
		else if (p.source === 'AI') {
			alignSelf = 'flex-start'
			backgroundColor = '#9EEE8A'
			color = 'black'
		}
		return `
			align-self: ${alignSelf};
			background-color: ${backgroundColor};
			color: ${color};
		`
	}}
	white-space: pre-wrap;
	word-wrap: break-word; /* This property is necessary for long words */
	overflow: visible;
	font-family: inherit;
`