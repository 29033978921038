import React, { useState, useRef, useEffect } from 'react'
import * as Constants from '../util/constants'

export default function MessageInput({ message, setMessage, send, sending }) {
	const textareaRef = useRef(null);

	const handleInput = e => {
		setMessage(e.target.value)
	}

	const handleKeyPress = async (event) => {
		if (event.key === 'Enter' && !event.shiftKey) {
			await handleSend()
		}
	}

	const handleSend = async () => {
		await send()
		// clear and reset the size
		// handleInput({ target: { value: '' }})
		// setValue('')
	}

	useEffect(() => {
		if (textareaRef.current) {
			textareaRef.current.style.height = "auto";
			textareaRef.current.style.height = `${textareaRef.current.scrollHeight}px`;
		}
	}, [message])

	return (
		<div style={{ display: 'flex', justifyContent: 'center' }}>
			<div style={{ position: 'relative', height: 31, width: 600}}>
				<textarea 
					placeholder='Send Message'
					rows={1}
					ref={textareaRef}
					// onInput={handleInput}
					onChange={handleInput}
					onKeyUp={handleKeyPress}
					disabled={sending}
					value={message}
					style={{
						backgroundColor: Constants.COLORS.GREY_DARK,
						width: 600,
						padding: 10,
						paddingRight: 43,
						borderRadius: 8,
						resize: 'none',
						maxHeight: 200,
						position: 'absolute',
						bottom: -10,
						border: 'none'
					}}
				/>
				<span
					className="material-symbols-outlined" 
					onClick={handleSend}
					style={{
						position: 'absolute',
						right: 8,
						bottom: 0
					}}
				>
					send
				</span>
			</div>
		</div>
	)
}